import React from "react";

import Layout from "components/Global/Layout";

const NotFoundPage = () => {
	return (
		<Layout amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas">
			<h1>Page Not Found</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</Layout>
	);
};

export default NotFoundPage;
